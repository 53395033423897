<template>
  <footer class="md-shared-footer" v-if="$route.path !==  '/koolearn' ">
    <div class="md-footer-container">
      <div class="left">
        <ul>
          <li @click="goPage('/home')">
            <a href="javascript:;">网站首页</a>
            <span class="split"> | </span>
          </li>
          <li @click="goPage('/newsDetail', 3)">
            <a href="javascript:;">关于我们</a>
            <span class="split"> | </span>
          </li>
          <li @click="goPage('/newsDetail', 4)">
            <a href="javascript:;">联系我们</a>
            <span class="split"> | </span>
          </li>
          <li @click="goPage('/newsDetail', 5)">
            <a href="javascript:;">帮助中心</a>
            <span class="split"> | </span>
          </li>
          <li @click="feedback">
            <a href="javascript:;">意见反馈</a>
          </li>
        </ul>
        <p>
          {{ copyright ? `${copyright} | ` : ""
          }}<a
            rel="nofollow"
            href="https://beian.miit.gov.cn"
            style="color: #93999f"
            target="https://beian.miit.gov.cn/"
            >蜀ICP备2021004208号-2</a
          >
          <a
            rel="nofollow"
            href="http://www.beian.gov.cn/portal/registerSystemInfo"
            style="color: #93999f; margin: 0 5px"
            target="_blank"
            ><img src="../assets/img/icon-gongan.png" />蜀ICP备2021004208号-2</a
          >
        </p>
      </div>
    </div>
    <el-dialog
      title="意见反馈"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="content">
          <el-input
            type="textarea"
            v-model="form.content"
            :autosize="{ minRows: 4, maxRows: 4 }"
            maxlength="500"
            show-word-limit
            placeholder="请填写具体内容帮助我们了解您具体的意见与建议。"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload
            :headers="headers"
            accept=".jpg,.png,.jpeg"
            action="/video-dev/common/upload"
            list-type="picture-card"
            :limit="3"
            show-file-list
            :file-list="listData"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <p>
            最多可上传<span style="color: #db192a">3</span
            >张图片，图片大小不超过<span style="color: #db192a">2M</span>
          </p>
        </el-form-item>
        <el-form-item prop="contactWay">
          <el-input
            placeholder="请留下真实联系方式(邮箱、QQ)，方便我们答疑解惑！"
            v-model="form.contactWay"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
    </el-dialog>
  </footer>
</template>
<script>
import { postFeedback, getConfigType } from "@/axios/home";
export default {
  name: "MdSharedFooterComponent",
  data() {
    return {
      dialogVisible: false,
      form: {
        contactWay: "",
        content: "",
      },
      rules: {
        contactWay: [
          { required: true, message: "内容不能为空！", trigger: "blur" },
        ],
        content: [
          { required: true, message: "内容不能为空！", trigger: "blur" },
        ],
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Authorization"),
      },
      filePath: "",
      listData: [],
      copyright: "",
    };
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem("isLogin");
    },
    userInfo() {
      return JSON.parse(window.localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 版权
    async getConfigType() {
      const res = await getConfigType("web:copyright");
      if (res.code === 200) {
        this.copyright = res.msg;
      }
    },
    goPage(path, query) {
      if (path !== "/home") {
        if (this.isLogin) {
          this.$router.push({
            path,
            query: {
              rid: query,
            },
          });
        } else {
          this.$confirm("是否去登录", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/login",
              });
            })
            .catch(() => {});
        }
      } else {
        this.$router.push({
          path,
          query: {
            rid: query,
          },
        });
      }
    },
    feedback() {
      if (this.isLogin) {
        this.dialogVisible = true;
      } else {
        this.$confirm("是否去登录", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {});
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.form.content = "";
      this.form.contactWay = "";
      this.listData = [];
      this.filePath = "";
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2M!");
      }
      return isLt2M;
    },
    handleSuccess(res, file) {
      console.log(res, file);
      this.filePath = "/video-dev" + res.fileName;
      if (res.code === 200) {
        const fileObj = {
          name: file.name,
          url: this.filePath,
          path: res.url,
        };
        this.listData.push(fileObj);
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.listData = fileList;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.userNick = this.userInfo.nickName;
          const option = {
            ...this.form,
            imgs: this.listData.map((e) => e.path).join(),
          };
          const res = await postFeedback(option);
          if (res.code === 200) {
            this.$message.success("提交成功");
            this.handleClose();
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.md-shared-footer {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: @md-footer-height;
  box-sizing: border-box;
  border-top: 3px solid #0FB09B;
  background: #fff;

  .md-footer-container {
    width: @md-main-width;
    height: 100%;
    box-sizing: border-box;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      ul {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        li {
          font-size: 16px;
          // font-weight: 500;
          color: #888;
          // margin-right: 40px;
          a {
            color: #888;
            &:hover {
              color: #333;
            }
          }
          span {
            color: #ddd;
            display: inline-block;
            margin: 0 30px;
            font-weight: normal;
          }
          &:last-child {
            margin-right: 0px !important;
          }
        }
      }

      p {
        font-size: 14px;
        font-weight: 300;
        color: #545c63;
        display: flex;
        align-items: center;
        margin-top: 10px;
        a {
          &:hover {
            color: #333 !important;
          }
          img {
            margin: -3px 5px 0 10px;
          }
        }
      }
    }
  }
}
/deep/ .el-dialog__body {
  padding: 0 20px;
}
</style>
